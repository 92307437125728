<template>
    <div id="goals" style="height:100%; width:100%;">
        <b-col class="px-0 mx-0" style="height:100%; width:100%;">
            <DefaultPageComponent
            :loading="loading"
            :title="'Oportunidade de melhoria em fundos'"
            :haveBackButton="true"
            @onBack="$router.push({name: 'topFunds'})"
            >

            <template #filters>
                <b-row class="m-0">

                    <b-col class="px-2">
                        <div class="form-group">
                            <label for="">Assessor:</label>
                            <b-select class="form-control form-control-sm" id="inputPesquisarPor" style="box-shadow: none; height: 56px;"
                            @change="getCustomers" :value="null" v-model="advisor" :disabled="loading">	
                                <option v-for="item,idx in advisors" :key="idx" :value="item.id"> {{ item.first_name + " " + item.last_name }} </option>
                            </b-select>
                        </div>
                    </b-col>
                    <b-col class="px-2">
                        <div class="form-group">
                            <label for="">Cliente:</label>
                            <b-form-select class="form-control form-control-sm" id="inputPesquisarPor" style="box-shadow: none; height: 56px;"
                            v-model="customer" :disabled="loading">	
                                <option :value="null"> {{ 'Todos' }} </option>
                                <option v-for="item,idx in customers" :key="idx" :value="item"> 
                                    {{ item.xp_code+" - "+item.customer_full_name }}
                                </option>
                            </b-form-select>
                        </div>
                    </b-col>
                    <div class="px-1"></div>
                    <CustomButton
                    @click="selectCustomer"
                    > Pesquisar
                    </CustomButton>
                    <b-col></b-col>
                </b-row>
            </template>

            <!-- CONTENT -->
            <div style="height: 100%" v-if="render">
                <b-row class="m-0 h-100">
                    <b-col cols="6" style="height:100%;">
                        <b-col class="py-3 my-3" style="max-width:max-content; height:calc(100% - 40px); background-color:#262625; border-radius:12px;">
                            <h5>Top Funds</h5>
                            <TopFundsOpportunitiesTable ref="tableOp" :canSelect="!customer" :items="modal ? modal : []" height="calc(100% - 40px)" width="min-content"
                            @select="(value)=>{
                                if(!customer) item=value;
                                loadOpportunities();
                            }"/>
                        </b-col>
                    </b-col>
                    <b-col cols="6" class="py-3" style="height:100%;">
                        <b-row class="m-0" align-h="center">
                            <b-col class="p-3" style="width:80%; max-width:fit-content; background-color:#262625; border-radius:12px;">
                                <h5>Top Funds</h5>
                                <TopFundsOpportunitiesTable :items="item ? [item] : []" height="calc(100% - 30px)" width="100%"/>
                            </b-col>
                        </b-row>

                        <div class="py-3"></div>

                        <b-row class="m-0" align-h="center" :style="'height:calc(100% - '+(item ? 235 : 145)+'px);'">
                            <b-col class="p-3" style="background-color:#262625; border-radius:12px; height:100%;">
                                <h5>Fundos da carteira</h5>
                                <b-table class="default-table" style="max-height:calc(100% - 40px) !important; overflow:auto; background-color:white !important"
                                :selectable="customer?true:false" sticky-header responsive 
                                :items="opportunities" 
                                select-mode="single"
                                @row-selected="(selected)=>{
                                    selectedRow = selected[0];
                                    item=modal.find(e=>e.items.find(i=>i.target.opportunities.find(o=>o==selected[0])));
                                    if(item) {
                                        item.items=[item.items.find(i=>i.target.opportunities.find(o=>o==selected[0]))];
                                        $refs.tableOp.modal=item.items[0];
                                    }
                                    else{
                                        $refs.tableOp.modal=null;
                                    }
                                }"
                                :tbody-tr-class="rowClass"
                                :fields="[
                                { 
                                    key: 'customer_code',
                                    label: 'Código cliente',
                                    class: 'text-right',
                                    sortable: true,
                                },
                                { 
                                    key: 'customer_name',
                                    label: 'Nome',
                                    class: 'text-left',
                                    sortable: true,
                                },
                                { 
                                    key: 'n_document',
                                    label: 'CNPJ',
                                    class: 'text-left',
                                    sortable: true,
                                },
                                { 
                                    key: 'fund',
                                    label: 'Fundo de Investimento',
                                    class: 'text-left',
                                    sortable: true,
                                },
                                { 
                                    key: 'absolute_return_12_months',
                                    label: 'ROA',
                                    class: 'text-right',
                                    sortable: true,
                                },
                                { 
                                    key: 'volatility',
                                    label: 'Vol.',
                                    class: 'text-right',
                                    sortable: true,
                                },
                                { 
                                    key: 'quantity',
                                    label: 'Quantidade',
                                    class: 'text-right',
                                    sortable: true,
                                },
                                { 
                                    key: 'net',
                                    label: 'NET',
                                    class: 'text-right',
                                    sortable: true,
                                },
                                ]">
                                    <template #cell="{ rowSelected }">
                                        <template v-if="rowSelected">
                                            <div style="opacity:1"></div>
                                        </template>
                                        <template v-else>
                                            <div style="opacity:0.7"></div>
                                        </template>
                                    </template>
                                </b-table>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </div>

            <template #footer>
                <div></div>
            </template>

            </DefaultPageComponent>
        </b-col>
    </div>
</template>

<script>
import DefaultPageComponent from '@/components/DefaultPageComponent.vue';
import { mapState } from 'vuex';
import CustomButton from '@/components/CustomButton.vue';
import TopFundsOpportunitiesTable from '@/components/TopFundsOpportunitiesTable.vue';

export default {
    name: "TopFundsOpportunitiesView",
    components:{
        DefaultPageComponent,
        TopFundsOpportunitiesTable,
        CustomButton,
    },
    data(){
        return {
            render: true,
            loading: false,
            loadingSubmit: false,

            query: null,

            modal: [],
            items: [],
            opportunities: [],
            item: null,
            selectedRow:null,

            advisor: null,
            customer: null,

            advisors: [],
            customers: [],
        }
    },
    async created(){
        this.loading=true;
        await this.getAdvisors();
        this.getItems();
    },
    computed: {
        ...mapState('user', {
            user: (state) => state.user,
        }),
    },
    methods: {
        rowClass(item) {
            if (!item) return "row-selected";
            return this.selectedRow == item || !this.item || !this.customer || !this.selectedRow
                ? "row-selected"
                : "row-unselected";
        },
        async forceRender(){
            this.render = false;
            await this.$nextTick();
            this.render=true;
        },
        clear(){
            this.item = null;
            this.modal = null;
            this.selectedRow = null;
            this.forceRender();
        },
        getUserAdvisor(){ 
            this.advisor = this.advisors.find(e=>e.id==this.user.advisor_id).id
            this.getCustomers();
        },
        async getCustomers(){
            try{
                const response = await this.$axios.get('advisors/customers-details?advisor_id='+this.advisor)
                this.customers = response.data[0]?.customers;
            }
            catch(error){
                console.log(error);
            }
        },
        async getItems(){
            try{
                this.loading=true;
                var endpoint = 'top-funds-opportunity';
                endpoint+='?advisor_id='+this.advisor;
                if(this.customer) endpoint+='&customer_id='+this.customer.xp_code;
                const response = await this.$axios.get(endpoint);
                this.modal = response.data;
                this.modal.sort((a,b)=>a.position-b.position)
            }
            catch(error){
                this.$swal({
                    title: 'Erro',
                    text: 'Não foi possível consultar listagem de Top Funds Oportunidades.',
                    icon: 'error',
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#C44C30',
                    allowOutsideClick: false,
                });
                console.log(error);
            }
            finally{ this.loading=false; }
        },
        async getAdvisors () {
            try{
                const user = {...this.user};
                const response = await this.$axios.get('advisors')
                this.advisors = response.data.data;
    
                if (user.hunter_ids.length > 0)
                    this.advisors = this.advisors.filter(advisor => {
                        return user.hunter_ids.includes(advisor.id)
                    });
                this.getUserAdvisor();
            }
            catch(error){
                console.log(error);
            }
        },
        async selectCustomer(){
            this.loading=true;
            this.clear();
            await this.getItems();
            this.loadOpportunities();
        },
        loadOpportunities(){
            if(this.customer) this.opportunities = this.modal?.flatMap(group=>group.items.flatMap(item=>item.target?.opportunities)) 
            else this.opportunities = this.item?.items[0]?.target?.opportunities
        },
        showSenders(item){
            item;
        },
        showDetails(item){
            item;
        },
    },
}
</script>

<style scoped>
* /deep/ th {
    border: none !important;
}
* /deep/ .row-selected {
  opacity: 1 !important;
}
* /deep/ .row-unselected {
  opacity: 0.7 !important;
}
* /deep/ tbody tr {
  transition: 0.25s !important;
}
</style>